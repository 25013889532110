﻿(function () {
  if (document.querySelector(".usp-carousel")) {
    var slider = tns({
      container: '.usp-carousel',
      controls: false,
      autoplay: true,
      nav: false,
      gutter: 16,
      autoplayButtonOutput: false,
      speed: 900,
      autoplayHoverPause: true,
      items: 1,
      slideBy: 1,
      autoplayTimeout: 3000,
      useLocalStorage: false,
      onInit: function () {
        document.getElementsByClassName("usp-carousel")[0].classList.add("init");
      },
      responsive: {
        576: {
          items: 2,
          slideBy: 2,
          autoplayTimeout: 4000
        },
        992: {
          items: 3,
          slideBy: 3,
          autoplayTimeout: 5000
        }
      }
    });
  }
})();
