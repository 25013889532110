﻿(function () {

    window.addEventListener("load", function () {

        //Added to clear on browser back button
        let manList = document.getElementById("manList");
        if (manList) {
            manList.selectedIndex = 0;
        }
        let ranList = document.getElementById("ranList");
        if (ranList) {
            ranList.selectedIndex = 0;
        }
        let minPrice = document.getElementById("PriceRangesForMinBudgetDropdown");
        if (minPrice) {
            minPrice.selectedIndex = 0;
        }

        let priceRangesForMaxBudgetDropdown = document.getElementById("PriceRangesForMaxBudgetDropdown");
        if (priceRangesForMaxBudgetDropdown) {
            priceRangesForMaxBudgetDropdown.selectedIndex = 0;
        }

    });

    window.addEventListener("beforeunload", function (e) {
        // reset the manufacturer and range dropdowns on unload
        // ensures the dropdowns are reset if the user uses the browser back button
        let manList = document.getElementById("manList");
        if (manList) {
            manList.selectedIndex = 0;
        }
        let ranList = document.getElementById("ranList");
        if (ranList) {
            ranList.selectedIndex = 0;
        }
    });

    if (document.getElementById("manList")) {
        document.getElementById("manList").addEventListener("change", function () {
            refreshRanges();
        });
    }

    if (document.getElementById("ranList")) {
        document.getElementById("ranList").addEventListener("change", function () {
            refreshPriceRanges();
        });
    }

    [document.getElementById("PriceRangesForMinBudgetDropdown"), document.getElementById("PriceRangesForMaxBudgetDropdown")].forEach(function (item) {
        if (item) {
            item.addEventListener('change', function (e) {
                var pricesJson = document.getElementById("PriceRangeDataJson").value;
                var prices = JSON.parse(pricesJson);
                populatePriceRange(prices);
            })
        }
    })

    function getPriceRangesCallback(data) {
        document.getElementById("PriceRangeDataJson").value = JSON.stringify(data);
        populatePriceRange(data);
    }

    function refreshRanges() {
        var man = document.getElementById("manList");
        var selectedManufacturer = man.options[man.selectedIndex].text;

        var ran = document.getElementById("ranList");
        var selectedRange = ran.options[ran.selectedIndex].text;

        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {
                if (xmlhttp.status === 200) {
                    var modelsHtml = JSON.parse(xmlhttp.responseText);
                    populateRangeDropdown(modelsHtml);
                    refreshPriceRanges();
                }
            }
        };
        xmlhttp.open("POST", "/Service/GetAvailableRangesHome", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.setRequestHeader('x-csrf-token', document.querySelector("[name=__RequestVerificationToken]").value);
        xmlhttp.send("manufacturer=" + selectedManufacturer + "&financeType=Personal&selectedRange=" + selectedRange);
    }

    function refreshPriceRanges() {
        var man = document.getElementById("manList");
        var selectedManufacturer = man.options[man.selectedIndex].text;

        var ran = document.getElementById("ranList");
        var selectedRange = ran.options[ran.selectedIndex].text;

        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {
                if (xmlhttp.status === 200) {
                    var prices = JSON.parse(xmlhttp.responseText);
                    getPriceRangesCallback(prices);
                }
            }
        };
        xmlhttp.open("POST", "/Service/GetPriceRanges", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.setRequestHeader('x-csrf-token', document.querySelector("[name=__RequestVerificationToken]").value);
        xmlhttp.send("vehicleType=Both&manufacturer=" + selectedManufacturer + "&financeType=Personal&range=" + selectedRange);
    }

    function populateRangeDropdown(modelsHtml) {
        document.getElementById("ranList").innerHTML = modelsHtml;
    }

    function populatePriceRange(prices) {
        var priceTo = document.getElementById("PriceRangesForMaxBudgetDropdown"),
            priceFrom = document.getElementById("PriceRangesForMinBudgetDropdown"),
            selectedMinPrice = priceFrom.options[priceFrom.selectedIndex].value,
            selectedMaxPrice = priceTo.options[priceTo.selectedIndex].value,
            htmlDropDownFrom = "",
            htmlDropDownTo = "",
            pricesCount = prices.length,
            minPrice = parseInt(selectedMinPrice, 10),
            maxPrice = parseInt(selectedMaxPrice, 10);

        var minPriceOption = option(0, minPrice, "£ min");
        var maxPriceOption = option(0, maxPrice, "£ max");

        htmlDropDownFrom += minPriceOption;
        htmlDropDownTo += maxPriceOption;

        for (var i = 0; i < pricesCount; i++) {
            var value = prices[i];
            var text = "£" + value.toLocaleString()

            if (minPrice === 0 || value > minPrice) {
                htmlDropDownTo += option(value, maxPrice, text);
            }
            if (maxPrice === 0 || value < maxPrice) {
                htmlDropDownFrom += option(value, minPrice, text);
            }
        }
        priceTo.innerHTML = htmlDropDownTo;
        priceFrom.innerHTML = htmlDropDownFrom;
    }

    function option(value, selectedValue, text) {
        return "<option " + (value === selectedValue ? "selected" : "") + " value=\"" + value + "\">" + text + "</option>";
    }

    window["SearchDeals"] = function (gaEventCategory) {
        var man = document.getElementById("manList"),
            ran = document.getElementById("ranList"),
            priceTo = document.getElementById("PriceRangesForMaxBudgetDropdown"),
            priceFrom = document.getElementById("PriceRangesForMinBudgetDropdown"),
            financeType = "Personal",
            rangeValue = ran.value,
            manufacturerValue = man.value,
            rangeText = ran.options[ran.selectedIndex].text,
            manufacturerText = man.options[man.selectedIndex].text,
            manufacturerPath = manufacturerValue !== "" ? manufacturerValue + "/" : "",
            rangePath = rangeValue !== "" ? rangeValue + "/" : "",
            priceFromValue = priceFrom.options[priceFrom.selectedIndex].value,
            priceToValue = priceTo.options[priceTo.selectedIndex].value;

        var vehicleType;
        if (rangeValue !== "") {
            vehicleType = ran.options[ran.selectedIndex].getAttribute("data-type").replace("both", "car");
        } else {
            vehicleType = man.options[man.selectedIndex].getAttribute("data-type").replace("both", "car");
        }

        redirectToSearchPage(financeType, priceFromValue, priceToValue, vehicleType, manufacturerPath, rangePath, manufacturerValue, rangeValue, manufacturerText, rangeText);
    }

    function redirectToSearchPage(financeType, priceFrom, priceTo, vehicleType, manufacturerPath, rangePath, manufacturerValue, rangeValue, manufacturerText, rangeText) {
        var searchResultUrl = '/' + vehicleType + '-leasing/' + manufacturerPath + rangePath;

        var searchParams = new URLSearchParams();
        searchParams.append("finance", financeType.toLowerCase());
        if (priceFrom && priceFrom != 0) {
            searchParams.append("price_from", priceFrom);
        }
        if (priceTo && priceTo != 0) {
            searchParams.append("price_to", priceTo);
        }
        var querystring = searchParams.toString();
        if (querystring) {
            searchResultUrl = searchResultUrl + "?" + querystring;
        }

        window.location.href = searchResultUrl;
    }

})();